import React from 'react'
import { Helmet } from 'react-helmet'
import CookieConsent from 'react-cookie-consent'
import 'normalize.css'
import './common.css'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#f2f2f2" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <div>{children}</div>
      <CookieConsent
        location="bottom"
        buttonText="Alright, I understand"
        cookieName="essentialCookies"
        style={{
          background: '#3c5c7d',
          fontSize: '0.8rem',
          alignItems: 'center',
          fontFamily: 'Montserrat Medium, sans-serif',
        }}
        buttonStyle={{
          padding: '0.4rem 1rem',
          backgroundColor: '#e17a82',
          fontSize: '0.8rem',
          color: 'white',
          borderRadius: '5px',
        }}
      >
        We are using only essential cookies. these cookies are necessary for a
        website to function properly.
      </CookieConsent>
    </>
  )
}

export default TemplateWrapper
